<template>
  <div>
      <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {  },
  data(){
    return {
      
    }
  },

  computed: {
  },

  methods: {
   
  },

  async created(){
    this.$store.dispatch('getStats')
    this.$store.dispatch('enterprises/getAllEnterpriseUsers')
    this.$store.dispatch('particulars/getAllParticularUsers')
  }
}
</script>

<style>

</style>